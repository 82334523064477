<template>
    <div class="teachingUploadDialog">
        <el-dialog title="导入学生" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>

                <el-col :span="16">
                    <el-upload class="upload-demo" drag action
                               :multiple="false"
                               :show-file-list="false"
                               accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               :http-request="httpRequest">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传excel文件，自动识别数据格式</div>
                    </el-upload>
                </el-col>
                <el-col :span="8">
                    <el-link :href="downloadUrl+'/teaching/student_demo.xlsx'" download="学生示例.xlsx" type="primary">学生示例下载</el-link>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-table :data="tableData" :row-style="uploadRowStyle" style="width: 100%" align='center'>
                        <el-table-column prop="myNo" label="学号"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="name" label="用户名"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="nickName" label="姓名"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="schoolName" label="所属单位" align='center' ></el-table-column>
                        <el-table-column prop="hardClassName" label="行政班" align='center' ></el-table-column>
                        <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                        <el-table-column prop="professionName" label="专业" align='center' ></el-table-column>
                        <el-table-column prop="phoneNo" label="联系方式" align='center' ></el-table-column>
                        <el-table-column prop="status" label="状态" align='center'  :formatter="formatterStatus"></el-table-column>
                        <el-table-column prop="operation" align='center' label="操作" >
                            <template slot-scope='scope'>
                                <el-button icon='edit' size="mini" type="danger" @click='deleteStudent(scope.$index)'>移除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>

            </el-row>
            <el-row style="margin-top: 20px;">
                <el-col :span="8" :offset="8">
                    <el-button type="primary" :loading="uploading"  v-if="tableData.length > 0" :disabled="!isCanUpload" @click="submitUpload">确定上传</el-button>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import XLSX from "xlsx";

    export default {
        name: 'teachingUploadDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, uploading: false, downloadUrl: null,
                tableData: [], isCanUpload: false,
                teachingClassId: null,
                searchForm:{ pageNo: 1, pageSize: 200, totalCount: 0, searchText: null, schoolId: null, professionId: null, year: null,},
                studentCol: [{prop: 'name', label: '姓名', colName: '姓名', check: true, }, {prop: 'myNo', label: '学号', colName: '学号', check: true, },
                    {prop: 'hardClassName', label: '行政班名称', colName: '行政班名称',  }, {prop: 'schoolName', label: '单位', colName: '单位' },
                    {prop: 'year', label: '入学年', colName: ' 入学年', }, {prop: 'professionName', label: '专业', colName: '专业',  },
                    {prop: 'phoneNo', label: '手机号', colName: '手机号（选填）', },
                    {prop: 'status', label: '状态', colName: null, }],
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.downloadUrl = window.g.DEMO_URL;
	    },
        props: {
            teachingClass: {
                type: Object
            }
        },
        watch: {
            teachingClass: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        this.teachingClassId = n.id
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('ok');
            },
            formatterStatus(row, column, cellValue, index) {
                if(column.property == 'status'){
                    if(cellValue == '00') return '通过';
                    if(cellValue == '01') return '不存在';
                    if(cellValue == '02') return '检查中';
                    if(cellValue == '10') return '上传成功';
                    if(cellValue == '11') return '上传失败';
                    if(cellValue == '98') return '必填值为空';
                    if(cellValue == '99') return '无法上传';

                }
                return cellValue;

            },
            uploadRowStyle({row, rowIndex}){
                if(row.status == '99' || row.status == '98' || row.status == '03' || row.status == '11'){
                    console.log(rowIndex, row);
                    return {color: 'red' };
                }
                if(row.status == '00'){
                    console.log(rowIndex, row);
                    return {color: 'grey' };
                }
            },
            httpRequest(e) {
                let file = e.file; // 文件信息
                if (!file) {
                    // 没有文件
                    return false;
                } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                    // 格式根据自己需求定义
                    this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
                    return false;
                }

                const fileReader = new FileReader();
                let this_ = this;
                fileReader.onload = ev => {
                    try {
                        const data = ev.target.result;
                        const workbook = XLSX.read(data, {
                            type: "binary" // 以字符编码的方式解析
                        });
                        console.log(workbook)
                        const exlname = workbook.SheetNames[0]; // 取第一张表
                        let exlData = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
                        console.log(exlData);
                        if(!exlData || exlData.length == 0){
                            this.$message.error("数据格式错误");
                            return false;
                        }

                        if(this.studentFound(exlData)) {
                            this.isCanUpload = true;
                            this.moduleName = 'student';
                            this.postChecking('student');
                            return ;
                        }

                        this.$message.error("数据格式错误");
                        return false;

                    } catch (e) {
                        console.log(e);
                        console.log("出错了：：", e);
                        return false;
                    }
                };
                fileReader.readAsBinaryString(file);
            },
            studentFound(exlData){
                let first = exlData[0];
                if('姓名' in first && '学号' in first){
                    this.readAndSetData(exlData, this.studentCol);
                    return true;
                }
                return false;
            },
            readAndSetData(exlData, columns){
                let tableData = [];

                for(let i=0; i<exlData.length;i++){
                    let d = exlData[i];
                    let p = { status: '02' }
                    for(let j=0; j<columns.length; j++){
                        let col = columns[j];
                        if(col.colName != null && col.colName in d) {
                            let v = String(d[col.colName]);
                            if("check" in col && col.check && (v==null || v=='')) p.status = '98';
                            p[col.prop] = v
                        }
                    }
                    tableData.push(p);
                }
                console.log('readAndSetData', tableData)
                this.tableData = tableData;
            },
            postChecking(moduleName){
                this.isCanUpload = false;
                let p = { licenceId: this.userInfo.licenceId, moduleName: moduleName, data: this.tableData };
                let param = { controllerName: 'teacherStudent/upload', methodName: '/check', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('postChecking', code);
                    if (code == 0) {
                        this.tableData =data;
                        for(let i=0; i<data.length; i++){
                            let d = data[i];
                            if(d.status == '99'){
                                this.isCanUpload = false;
                                return;
                            }
                        }
                        this.isCanUpload = true;
                    }

                }).catch((error) => {
                    this.isCanUpload = false;
                });
            },
            submitUpload(){

                let tableData = [];
                let isUpload = false;
                for(let i=0; i<this.tableData.length;i++){
                    let d = this.tableData[i];
                    if(d.status == '99' || d.status == '98' || d.status == '11'){
                        continue;
                    }
                    tableData.push(d);
                    isUpload = true;
                }
                if(!isUpload){
                    this.$message.error("无效数据，请重新整改再上传");
                    return false;
                }
                this.uploading = true;
                let p = { licenceId: this.userInfo.licenceId, teachingClassId: this.teachingClassId, data: tableData };
                let param = { controllerName: 'teacherStudent/upload', methodName: '/save', param: p};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('upload', code, data);
                    if (code == 0) {
                        this.tableData =data;
                        this.uploading = false;
                        this.isCanUpload = false;
                    }

                }).catch((error) => {
                    this.uploading = false;
                });
            },
            deleteStudent(index){
                this.tableData.splice(index,1);
            },



        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teachingUploadDialog{

    }


</style>


