<template>
    <div class="teachingStudentDialog">
        <el-dialog title="学生列表" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>
                <el-col :span="24">
                    <el-table :data="tableData" style="width: 100%" align='center'>
                        <el-table-column prop="my_no" label="学号"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="name" label="用户名"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="nick_name" label="姓名"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="school_name" label="所属单位" align='center' ></el-table-column>
                        <el-table-column prop="hard_class_name" label="行政班" align='center' ></el-table-column>
                        <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                        <el-table-column prop="profession_name" label="专业" align='center' ></el-table-column>
                        <el-table-column prop="phone_no" label="联系方式" align='center' ></el-table-column>
                        <el-table-column prop="operation" align='center' label="操作" >
                            <template slot-scope='scope'>
                                <el-button icon='edit' size="mini" type="danger" @click='deleteStudent(scope.row)'>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>

            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import XLSX from "xlsx";

    export default {
        name: 'teachingStudentDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, uploading: false, downloadUrl: null,
                tableData: [], isCanUpload: false,
                teachingClassId: null,
                searchForm:{ pageNo: 1, pageSize: 200, totalCount: 0, searchText: null, schoolId: null, professionId: null, year: null,},

            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            teachingClass: {
                type: Object
            }
        },
        watch: {
            teachingClass: {
                deep: true, immediate:true,
                handler(n, o){
                    if(n){
                        this.teachingClassId = n.id;
                        this.loadStudentList();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('ok');
            },
            loadStudentList(){

                let search = { licenceId: this.userInfo.licenceId, teachingClassId: this.teachingClassId};
                let param = { controllerName: 'sclTeachingStudent', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;

                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            deleteStudent(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该教学班的学生, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'sclTeachingStudent', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadStudentList()
                        }
                    });
                }).catch(() => {

                });
            },



        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teachingStudentDialog{

    }


</style>


